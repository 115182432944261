<template>
  <div class="container page-contact-us">
    <div class="row">
      <div class="col-lg-12">
        <h1 class="text-center p-tb-1">Contact Us</h1>
        <div class="row departments-list">
          <div class="col-lg-4 col-sm-12 col-xs-12 department-wrapper" v-for="(dep, i) in departments" :key="i">
            <div class="department">
              <span class="dep-name">{{ dep.title }}</span>
              <div class="contactor" v-for="(contactor, j) in dep.contactors" :key="j">
                <div class="avatar">
                  <img :src="contactor.avatar" :alt="`${contactor.name} photo`" width="200" height="200" />
                </div>
                <div class="heading">
                  <component class="title" :is="contactor.tag || 'span'" v-bind="contactor.title.attributes || {}">
                    {{ contactor.title.text }}
                  </component>
                  <span class="subtitle">{{ contactor.position || '' }}</span>
                </div>
                <div class="info">
                  <span v-if="contactor.email" class="email">
                    <a :href="`mailto:${contactor.email}`">{{ contactor.email }}</a>
                  </span>
                  <span class="address">{{ contactor.address }}</span>
                  <span v-if="contactor.mobile" class="mobile">
                    <a :href="`tel:${contactor.mobile}`">{{ contactor.mobile }}</a>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="contact-us">
          <div class="image-handler">
            <img class="contact-us-image" src="/img/services/contact-us.png" alt="contact-us.png" />
          </div>
          <div class="form-handler">
            <ContactUsForm :request-types="types" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { MICRO_LOCAL_BUSINESS, setMicroMarkup, removeMicroMarkup } from 'Lib/MicroMarkup';
import MetaMixin from 'Lib/mixins/MetaMixin';
import ContactUsForm from 'Components/ContactUsForm';
import Services from 'Lib/enums/Services';

export default {
  name: 'ContactUs',
  components: { ContactUsForm },
  mixins: [MetaMixin],
  computed: {
    services() {
      return Services;
    },
    types() {
      return this.services.map(el => {
        return { text: el.title, value: el.title };
      });
    },
  },
  data: function() {
    return {
      departments: [
        {
          title: 'Canada department',
          contactors: [
            {
              title: {
                text: 'Valeriia Baliura',
                tag: 'span',
              },
              position: 'Business Development Manager',
              email: 'valeriia.baliura@vaporfly.com',
              address: '10 Park Lawn Rd, Toronto, ON Canada M8V 0H9',
              mobile: '+1(437)332-0082',
              avatar: '/img/contactor-ca.gif',
            },
          ],
        },
        {
          title: 'US department',
          contactors: [
            {
              title: {
                text: 'Lo Saelee',
                tag: 'span',
              },
              position: 'Account manager',
              email: 'lo.salee@vaporfly.com',
              address: '10461 Old Placerville Rd. Suite 150. Sacramento, CA 95827',
              mobile: '+1(530)515-1817',
              avatar: '/img/logo-small.svg',
            },
          ],
        },
        {
          title: 'EU department',
          contactors: [
            {
              title: {
                text: 'Andi Europe',
                tag: 'a',
                attributes: {
                  href: 'https://andi.global/en/',
                  rel: 'nofollow noopener',
                  target: '_blank',
                },
              },
              position: '',
              email: 'sales@vaporfly.com',
              address: 'Andi Europe d.o.o. Pul Vele Crikve 1 51000 Rijeka Croatia',
              mobile: '+385(91)605-6633',
              avatar: '/img/contactor-eu.svg',
            },
          ],
        },
      ],
    };
  },
  beforeMount() {
    this.$store.dispatch('setBreadcrumbs', [{ to: this.$route.path, title: 'Contact Us' }]);
    setMicroMarkup(MICRO_LOCAL_BUSINESS);
  },
  beforeDestroy() {
    removeMicroMarkup(MICRO_LOCAL_BUSINESS);
  },
};
</script>

<style type="text/css" lang="scss" scoped>
@media only screen and (max-width: 640px) {
  .page-contact-us {
    h1 {
      margin: 0;
    }
  }
  .department-wrapper {
    padding: 1rem 0;

    &:not(:first-child) {
      border-top: 1px solid #e0e0e0 !important;
    }
  }
}

.page-contact-us {
  padding-bottom: 2rem;
}
.departments-list {
  margin-bottom: 5rem;

  .department {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-self: center;

    .dep-name {
      display: inline-block;
      font-weight: bold;
      text-align: center;
      font-size: large;
    }

    .contactor {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: larger;
      line-height: 1.5rem;

      .avatar {
        border-radius: 50%;
        width: 200px;
        height: 200px;
        display: block;
        overflow: hidden;
        border: 2px solid var(--c-primary);
        margin: 1.5em 0;
      }
      .heading {
        display: flex;
        flex-direction: column;
        align-items: center;

        .title {
          font-weight: 500;
        }
        .subtitle {
          min-height: 1.5rem;
        }
      }
      .info {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        text-align: left;
        max-width: 300px;
        padding: 0.5rem 0;

        & > span {
          position: relative;
          margin-left: 30px;
          margin-bottom: 5px;

          &:before {
            content: '';
            display: block;
            position: absolute;
            left: -30px;
            top: 0;
            width: 20px;
            height: 20px;
            background-repeat: no-repeat;
            background-position: center bottom;
            background-size: contain;
          }

          &.email:before {
            background-image: url(/img/mail.svg);
          }
          &.mobile:before {
            background-image: url(/img/phone.svg);
          }
          &.address:before {
            background-image: url(/img/placeholder.svg);
          }
        }
      }
    }
  }
}
</style>
