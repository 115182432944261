export default [
  {
    title: 'Customization',
    text:
      'We customize vape devices and accessories to make them look like any brand or you can add a personal touch to your device and create a design that will match your individual request.',
    icon: '/img/services/customization.svg',
    button: {
      title: 'Get a Quote',
    },
  },
  {
    title: 'Production',
    text:
      'Over the last decade of our work with Chinese manufacturers, we have selected the best ones. Each supplier is proven by us and produces high-quality devices on time.',
    icon: '/img/services/production.svg',
    button: {
      title: 'Get a Quote',
    },
  },
  {
    title: 'Quality control',
    text: 'Our agents are located throughout China and they regularly visit factories for quality control.',
    icon: '/img/services/quality-control.svg',
    button: {
      title: 'Get a Quote',
    },
  },
  {
    title: 'Shipping',
    text: 'Our standard shipping option is FREE. All the orders are processed and dispatched swiftly.',
    icon: '/img/services/shipping.svg',
    button: {
      title: 'Get a Quote',
    },
  },
  {
    title: 'Testing',
    text: 'We have our own laboratory in the US and additionally test products for heavy metals.',
    icon: '/img/services/testing.svg',
    button: {
      title: 'Get a Quote',
    },
  },
];
