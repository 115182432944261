<template>
  <div v-if="sent" class="success-message">
    <p>Your request was successfully sent.</p>
  </div>
  <form
    v-else
    class="contact-form"
    action="javascript:void(0)"
    @submit="submit"
    @input="clearErrors"
    @change="clearErrors"
  >
    <input
      class="mt-1"
      :class="{ 'has-error': 'name' in errors }"
      required
      name="name"
      type="text"
      placeholder="Name *"
      v-model="form.name"
    />
    <input
      class="mt-1"
      :class="{ 'has-error': 'email' in errors }"
      required
      name="email"
      type="email"
      placeholder="Mail *"
      v-model="form.email"
    />
    <input
      class="mt-1"
      :class="{ 'has-error': 'phone' in errors }"
      required
      name="phone"
      pattern="^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$"
      type="tel"
      placeholder="Phone (Format: 123-456-7890) *"
      v-model="form.phone"
      @input="formatTel"
    />
    <DropDownList
      class="type-select mt-1"
      :class="{ 'has-error': 'name' in errors }"
      required
      :items="requestTypes"
      placeholder="Type of Request *"
      v-model="form.requestType"
      @change="clearErrors"
    />
    <textarea
      class="mt-1"
      :class="{ 'has-error': 'name' in errors }"
      required
      name="message"
      rows="8"
      placeholder="Message"
      v-model="form.message"
    />
    <button type="submit" class="block button bg-light-green rounded-5 uppercase bordered-light send-btn">
      <span class="block text-bold text-color-white">Send</span>
    </button>
  </form>
</template>

<script>
import DropDownList from 'Components/editor/DropDownList';

export default {
  name: 'ContactUsForm',
  components: { DropDownList },
  props: {
    defaultType: {
      type: [String, Number],
    },
    requestTypes: {
      type: Array,
      default: () => [{ text: 'Default', value: 'default' }],
    },
  },
  watch: {
    defaultType() {
      this.form.requestType = this.defaultType;
    },
    errors: {
      deep: true,
      handler: () => {},
    },
  },
  methods: {
    clearErrors() {
      this.errors = {};
    },
    setLoader(state) {
      this.$store.dispatch('loader', !!state);
    },
    submit() {
      this.setLoader(true);
      this.$api
        .sendContactForm(this.form)
        .then(() => {
          this.setLoader(false);
          this.sent = true;
          this.form = {
            name: '',
            email: '',
            phone: '',
            requestType: '',
            message: '',
          };
          setTimeout(() => {
            this.sent = false;
          }, 5000);
        })
        .catch(err => {
          this.setLoader(false);
          if (err.response) {
            const { violations } = err.response.data;
            if (violations) {
              this.errors = Object.fromEntries(violations.map(({ message, propertyPath }) => [propertyPath, message]));
            }
          }
        });
    },
    formatTel(e) {
      const { value } = e.target;
      if ([3, 7].includes(value.length)) {
        this.form.phone = `${value}-`;
      }
    },
  },
  data() {
    return {
      form: {
        name: '',
        email: '',
        phone: '',
        requestType: '',
        message: '',
      },
      errors: {},
      sent: false,
    };
  },
  created() {
    this.form.requestType = this.defaultType || '';
    if (this.$store.getters.isLoggedIn) {
      const { email, name, phone } = this.$store.state.user;
      this.form = { ...this.form, email, name, phone };
    }
  },
};
</script>

<style type="text/css" lang="scss">
.contact-form {
  .type-select {
    .dd-wrapper {
      .input-value {
        border-radius: 3px;
        border: 1px solid #e5e5e5;
        font-size: 13px;
        font-family: arial, sans-serif;
      }
    }
  }
  .send-btn {
    margin: 5px auto;
  }
  input,
  textarea {
    &.has-error {
      border-color: red;
    }
  }
  .type-select.has-error .input-value {
    border-color: red;
  }
}
.success-message {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
</style>
